import Decimal from "decimal.js";
import _ from 'lodash';
import type {H3Index12} from '@/lib/ApiResources';
import axios from "axios";
import EngineApi from "@/lib/EngineApi";
import {computed, ref} from "vue";
import PricingManager from "@/components/pages/map/lib/data/PricingManager";
import geojson2h3 from "geojson2h3";
import AuthManager from "@/lib/AuthManager";
import mitt from "mitt";

export type OfferDomain = {
	h3index12: H3Index12,
	price: Decimal,
};

export enum OfferInvalidReasons {
	Disabled,
	TooMany,
	NotSinglePiece
}

type NewState = {
	domains: OfferDomain[],
	domainsPrice: Decimal,
	transactionCosts: Decimal,
	totalPrice: Decimal,
	invalidReason: OfferInvalidReasons | null,
}

export default new class OfferManager {

	public emitter = mitt();

	public reactiveId = ref<number | null>();
	public reactiveLoading = ref<boolean>(false);
	public reactiveDomains = ref<OfferDomain[]>([]);
	public reactiveDomainsPrice = ref<Decimal>(new Decimal('0.00'));
	public reactiveDiscountPercentage = ref<Decimal>(new Decimal('0.00'));
	public reactiveTransactionCosts = ref<Decimal>(new Decimal('0.00'));
	public reactiveTotalPrice = ref<Decimal>(new Decimal('0.00'));
	public reactiveInvalidReason = ref<OfferInvalidReasons | null>();
	public reactivePaymentMethod = ref<string | null>();
	public reactivePaymentAmount = ref<string | null>();
	public reactiveConditionsAccepted = ref<null | boolean>(false);

	public reactiveDiscountPrice = computed<Decimal>(() => this.reactiveDomainsPrice.value.sub(this.reactiveTotalPrice.value as Decimal));

	// TODO High: Remove due to not used?
	public reactiveIsLoadingFinalOffer = computed<Boolean>(() => this.reactiveRunningLoadingFinalOffers.value > 0);

	private reactiveRunningLoadingFinalOffers = ref<number>(0);
	private requestFinalOfferActive: boolean = false;

	public reactiveProfileHasEnoughCredits = computed<Boolean | null>(() => {
		return AuthManager.reactiveProfile.value ? (new Decimal(AuthManager.reactiveProfile.value.creditsTotal)).gte(this.reactiveTotalPrice.value as Decimal) : null;
	});

	private testAmountInvalidations = 0;
	private testAmountFinalOffers = 0;
	private testLastInvalidatePromise?: Promise<unknown>;

	constructor() {
		this.reset();
	}

	public reset(): void {
		this.reactiveId.value = null;
		this.reactiveLoading.value = false;
		this.reactiveDomains.value = [];
		this.reactiveDomainsPrice.value = new Decimal('0.00');
		this.reactiveTransactionCosts.value = new Decimal('0.00');
		this.reactiveTotalPrice.value = new Decimal('0.00');
		this.reactiveDiscountPercentage.value = new Decimal('0');
		this.reactiveInvalidReason.value = null;
		this.reactivePaymentMethod.value = 'Preregistration Credit';
		this.reactivePaymentAmount.value = null;
		this.reactiveConditionsAccepted.value = false;

		this.emitter.emit('reset')
	}

	/**
	 * Clears props to make the offer local only again.
	 */
	public resetRemote(): void {
		this.reactiveId.value = null;
		this.reactiveTotalPrice.value = this.reactiveDomainsPrice.value;
		this.reactiveDiscountPercentage.value = new Decimal('0');
		this.reactiveInvalidReason.value = null;
		//this.reactivePaymentMethod.value = null;
		this.reactivePaymentAmount.value = null;
		this.reactiveConditionsAccepted.value = false;
	}

	// @ugly Passing pricingManager with this call. OfferManager is singleton and MapManager is not. Make OfferManager non-singleton and store it in MapManager?
	public async setH3Indices(h3indices12: H3Index12[], pricingManager: PricingManager): Promise<void> {

		// Set as loading.
		this.reactiveLoading.value = true;

		try {

			// Init new state, which we will apply later to avoid the UI to react to building-up offers.
			let newState: NewState = {
				domains: [],
				domainsPrice: new Decimal('0'),
				transactionCosts: new Decimal('0'),
				totalPrice: new Decimal('0'),
				invalidReason: null,
			};

			// Get country floor price.
			let guessedCountryFloorPrice = h3indices12.length > 0 ? await pricingManager.guessGeneralizedCountryFloorPriceForH3indices(h3indices12) : null;

			// Add each domain.
			for (let h3index12 of h3indices12) {
				await this.checkApplyH3Index12ToOffer(h3index12, pricingManager, newState, guessedCountryFloorPrice!);
			}

			// Adopt state. From here on, no awaits, no responding UI.
			this.reactiveDomains.value = newState.domains;
			this.reactiveDomainsPrice.value = newState.domainsPrice;

			// todo critical: why do we do this if we overwrite it one line below?
			this.reactiveTotalPrice.value = newState.totalPrice;

			this.reactiveTransactionCosts.value = newState.transactionCosts
			// Calculate total price.
			this.reactiveTotalPrice.value = this.reactiveDomainsPrice.value.add(newState.transactionCosts);

			//
			this.validate();

			//
			await this.invalidate();
		} finally {

			// TODO High: Use critical section?
			// Set as loaded.
			this.reactiveLoading.value = false;
		}
	}

	public async setPaymentMethod(value: string | null): Promise<void> {

		// Set
		this.reactivePaymentMethod.value = value;

		//
		await this.invalidate();
	}

	private async checkApplyH3Index12ToOffer(h3index12: H3Index12, pricingManager: PricingManager, newState: NewState, guessedCountryFloorPrice: Decimal): Promise<void> {

		// Get price
		let price = await pricingManager.getPriceOfH3index12(h3index12, guessedCountryFloorPrice);

		// No price, no available domain?
		if (price === false) {

			// Set invalid reason, if was not set already.
			newState.invalidReason = this.reactiveInvalidReason.value || OfferInvalidReasons.Disabled;

			// Abort
			return;
		}

		// Add price.
		newState.domainsPrice = newState.domainsPrice.add(price as Decimal);

		// Add transaction if the hexagon was already sold
 		newState.transactionCosts = newState.transactionCosts.add(
			 await pricingManager.getTransactionCostsForH3Index12(h3index12)
		)

		// Add domain.
		newState.domains.push({
			h3index12,
			price: price as Decimal,
		});
	}

	private validate(): void {

		// Too many?
		if (this.reactiveDomains.value.length > 1500)
			this.reactiveInvalidReason.value = OfferInvalidReasons.TooMany;

		// Not a single piece?
		else if (geojson2h3.h3SetToFeature(this.reactiveDomains.value.map(item => item.h3index12)).geometry.type !== 'Polygon')
			this.reactiveInvalidReason.value = OfferInvalidReasons.NotSinglePiece;

		// Clear due to no issue?
		else
			this.reactiveInvalidReason.value = null;
	}

	public async invalidate(): Promise<void> {
		await (this.testLastInvalidatePromise = new Promise(async resolve => {

			// Increase amount invalidations.
			this.testAmountInvalidations++;

			// Request a final offer due to we have all the data?
			if (this.canLoadFinalOffer && this.reactiveInvalidReason.value === null)
				await this.requestFinalOffer();

			// Clear (remote) id?
			else
				this.reactiveId.value = undefined;

			//
			resolve(undefined);
		}));
	}

	public setRequestFinalOfferActive(value: boolean) {
		this.requestFinalOfferActive = value;
	}

	private get canLoadFinalOffer(): boolean {
		return this.hasDomains && !!this.reactivePaymentMethod.value && AuthManager.isSignedIn() && this.requestFinalOfferActive;
	}

	private async requestFinalOffer(): Promise<void> {

		try {
			// Increase loadings.
			this.reactiveRunningLoadingFinalOffers.value++;

			// Request
			let response = await axios.post(EngineApi.buildUrl('/domain-nft/offer/create'), {
				paymentMethod: this.reactivePaymentMethod.value,
				domains: this.reactiveDomains.value.map((domain: any) => domain.h3index12),

				// TODO Medium: Remove support from engine.
				futureSellingPrice: null,
			});

			// Set data.
			this.reactiveId.value = response.data.id;
			this.reactiveDomains.value = _.map(response.data.domains, (domain: any) => ({
				h3index12: domain.h3index12,
				price: new Decimal(domain.price),
			}));
			this.reactiveDomainsPrice.value = new Decimal(response.data.domainsPrice);
			this.reactiveDiscountPercentage.value = new Decimal(response.data.discountPercentage);
			this.reactiveTransactionCosts.value = new Decimal(response.data.transactionCosts);
			this.reactiveTotalPrice.value = new Decimal(response.data.totalPrice);
			//this.reactiveInvalidReason.value = response.data.invalidReason || null;
			this.reactivePaymentAmount.value = response.data.paymentAmount || null;

			this.testAmountFinalOffers++;
		} finally {
			// Decrease loadings.
			this.reactiveRunningLoadingFinalOffers.value--;
		}
	}

	/* TODO High: Remove?
	public async getOfferTokenPaymentAmount(offer: Offer): Promise<BigNumber> {

		// Get decimals.
		let contract = await getContract('ERC20Token', false);

		let decimals = await contract.decimals();

		// Apply decimals to offer's total price.
		let tokenAmount = BigNumber.from(offer.totalPrice.ceil().toFixed());

		tokenAmount = tokenAmount.mul(BigNumber.from(10).pow(decimals));

		return tokenAmount;
	}
	*/

	public get hasDomains(): boolean {
		return this.reactiveDomains.value.length > 0;
	}

	async debugInstantBuy(): Promise<void> {

		// Store
		await axios.post(EngineApi.buildUrl('/domain-nft/offer/debug-instant-buy'), {
			domains: this.reactiveDomains.value.map((domain: any) => domain.h3index12),
		});

		// Reset
		this.reset();

	}

}
