<template>
	<div class="relative w-full flex flex-col">

		<template v-if="mounted">

			<UnderConstruction v-if="isMaintenance" v-model="isMaintenance"/>

			<template
				v-else-if="isLoggedIn">
				<TopBar />

				<router-view
					class="router-view"
					:class="{
						'h-full': !showFooter
					}" />

				<Footer
					v-if="showFooter"
					class="mt-auto"/>

				<Notifications/>

				<!-- Used to add the Facebook chat bubble to the application -->
				<div id="fb-root"></div>
				<div id="fb-customer-chat" class="fb-customerchat"></div>
			</template>

			<router-view
				v-else
				class="router-view"
				:class="{
						'h-full': !showFooter
					}" />

		</template>

	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Footer from './layout/Footer.vue';
import TopBar from './layout/TopBar.vue';
import Notifications from './layout/Notifications.vue';
import EmptyRouterView from '@/components/layout/EmptyRouterView.vue';
import Modal from '@/components/layout/Modal.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';
import {loadScript} from "vue-plugin-load-script";
import AuthManager from "@/lib/AuthManager";
import * as _ from "lodash";

let instance: any;

export default defineComponent({

	components: {Modal, EmptyRouterView, Notifications, TopBar, Footer, UnderConstruction},

	data() {
		return {
			isMaintenance: import.meta.env.VITE_MAINTENANCE_ACTIVE === 'true' && window.localStorage.getItem('app-isMaintenance') !== 'false',
			topBar: {
				height: 0,
			},
			mounted: false,
		};
	},

	async mounted()
	{
		// @ugly Vue antipattern.
		instance = this as any;

		// TODO Medium: Review and let the related test enable it.
		// Load the Facebook chat bubble
		if (!this.isMaintenance) { // && !Testing.isTesting) {

			await AuthManager.initPromise;

			// Show login?
			if(!this.isLoggedIn && !window.location.href.includes('/profile/auth') && !window.location.href.includes('/profile/auth/terms-agreement')) {
				this.$router.push('/profile/auth');
			}

			await loadScript('/scripts/facebookScript.js')
		}

		this.mounted = true;
	},

	getInstance(): any {
		return instance;
	},

	watch: {

		isMaintenance(newVal) {

			if (!newVal)
				window.localStorage.setItem('app-isMaintenance', 'false');
		},

	},

	computed: {

		showFooter()
		{
			return !_.find(this.$route.matched, (route) => {

				// Hide footer requested by main component?
				if((route.components?.default as any)?.showFooter === false)
					return true;

				// Hide footer requested by sub component?
				return _.find(route.children || [], subRoute => (subRoute?.components?.default as any)?.showFooter === false);
			});
		},

		isLoggedIn() {
			return AuthManager.reactiveIsLoggedIn.value;
		}
	}

});
</script>
<style lang="scss">
#fb-root {
	z-index: 100;
}
</style>
