import {format} from "vue-number-format/src/utils";
import {getCurrentInstance} from "vue";
import Decimal from "decimal.js";

export function vueNumberFormatTs(value: any, options: any = {}) {

    // Get options.
	const fnMergedOptions = Object.assign({}, (getCurrentInstance() as any).appContext.config.globalProperties.$vueNumberFormatOptions, options);

	// Convert Decimal to Number first?
	if (value instanceof Decimal)
		value = value.toNumber();

	// TODO Medium: Might have rounding errors?
	else if (typeof value === 'string')
		value = (new Decimal(value)).toNumber();

	if (options.roundable && Math.round(value) === value)
		fnMergedOptions.precision = 0;

	// Adopt precision from value?
	else if (options.precision === undefined)
		fnMergedOptions.precision = ('' + value).split('.')?.[1]?.length || 0;

	// Format
	return format(value, fnMergedOptions);
}
