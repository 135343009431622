<template>
	<div
		class="relative sticky top-0 flex flex-col justify-between border-b border-white border-opacity-20 bg-ecoblue lg:hidden z-40"
		id="header"
		data-testid="layout-topBar-MobileNavigation">
		<div class="flex h-16">
			<!-- Logo -->
			<a
				v-if="homepageUrl !== '/'"
				class="ml-4 mr-1 flex flex-shrink-0 items-center"
				:href="homepageUrl"
				target="_blank"
			>
				<img class="block h-8 w-auto" src="/img/common/icon-with-gradients.svg" alt="Logo icon"/>
			</a>

			<router-link v-else class="ml-4 mr-1 flex flex-shrink-0 items-center" to="/">
				<img class="block h-8 w-auto" src="/img/common/icon-with-gradients.svg" alt="Logo icon" />
			</router-link>

			<div class="relative z-10 flex flex-1 items-center justify-center px-2">
				<MapSearch class="w-full" />
			</div>

			<!-- Menu toggle -->
			<div class="relative z-10 flex items-center">
				<button
					type="button"
					class="inline-flex items-center justify-center rounded-full bg-ecoheaderblue p-2 text-white hover:opacity-50 h-[2.75rem] w-[2.75rem]"
					@click="open = !open"
					data-testid="openMenu">

					<span class="sr-only">Open menu</span>

					<svg
						class="h-6 w-6"
						:class="{ hidden: open, block: !open }"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M4 6h16M4 12h16M4 18h16"
						></path>
					</svg>

					<svg
						class="h-6 w-6"
						:class="{ block: open, hidden: !open }"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M6 18L18 6M6 6l12 12"
						></path>
					</svg>
				</button>
			</div>

			<div class="relative z-10 flex items-center">
				<Profile v-if="AuthManager.reactiveIsLoggedIn.value" />

				<Auth class="m-0 mx-2 block w-8 md:w-24 lg:mx-4 lg:w-full" v-else />
			</div>
		</div>

		<!-- Dropdown -->
		<nav
			v-if="open"
			class="absolute top-16 w-full border-b bg-ecoblue"
			:class="{ 'border-b-econeonblue border-opacity-30': !$route.path.startsWith('/map'), 'border-transparent': $route.path.startsWith('/map') }"
		>

			<!-- Menu items -->
			<div class="grid grid-cols-1 gap-4 px-4 pt-2 pb-3">
				<router-link
					v-for="menuItem of menuItems"
					:to="(menuItem as any).to"
					v-slot="{ href, route, navigate, isActive, isExactActive }"
					custom
				>
					<div v-if="(menuItem as any).hasOwnProperty('subItems')">
						<a
							class="block rounded-md py-2 px-3 text-base font-medium text-white"
							:href="href"
							@click="onMenuItemClick($event, navigate)"
						>
							{{ menuItem.label }}
						</a>

						<router-link
							v-for="subMenuItem of menuItem.subItems"
							:to="subMenuItem.to"
							v-slot="{ href, route, navigate, isActive, isExactActive }"
							custom
						>
							<a
								v-if="!subMenuItem.to.includes('://')"
								class="flex items-center block rounded-md py-2 px-3 text-base font-medium text-white"
								:class="isActive ? 'bg-econeonblue text-white' : ''"
								:href="href"
								@click="onMenuItemClick($event, navigate)"

							>
								<img class="h-4 w-4 mr-2" src="/img/layouts/header/mobile-navigation-arrow.svg">
								<span> {{ (subMenuItem as any).label }} </span>
							</a>

						</router-link>
					</div>
					<a
						v-else-if="!(menuItem as any).to.includes('://')"
						class="block rounded-md py-2 px-3 text-base font-medium text-white"
						:class="isActive || $route.path.startsWith(href) ? 'bg-econeonblue text-white' : ''"
						:href="href"
						@click="onMenuItemClick($event, navigate)"
					>
						{{ (menuItem as any).label }}
					</a>

					<a
						v-else
						class="block rounded-md py-2 px-3 text-base font-medium text-white"
						:href="(menuItem as any).to"
						target="_blank"
					>
						{{ (menuItem as any).label }}
					</a>
				</router-link>
			</div>

			<!--			<div class="bg-white px-4 pb-4 pt-2 xl:pl-3 xl:pr-6 xl:py-3">-->

			<!--				<Profile-->
			<!--					v-if="AuthManager.reactiveIsLoggedIn.value"/>-->

			<!--				<Auth-->
			<!--					class="block w-24 lg:w-full m-0 lg:mx-4"-->
			<!--					v-else/>-->

			<!--			</div>-->

			<!-- Extra menu items -->
			<!--
			<div class="border-t border-gray-800 px-3 pt-4 pb-3">

				<div class="px-4 flex items-center">
					<div class="flex-shrink-0">
						<img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=80" alt="">
					</div>
					<div class="ml-3">
						<div class="text-base font-medium text-gray-800">Lisa Marie</div>
						<div class="text-sm font-medium text-gray-500">lisamarie@example.com</div>
					</div>
					<button type="button" class="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
						<span class="sr-only">View notifications</span>
						<svg class="h-6 w-6" x-description="Heroicon name: outline/bell" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
								  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
						</svg>
					</button>
				</div>

				<div class="mt-3 px-2 space-y-1">

					<a href="#" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Your Profile</a>

					<a href="#" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Settings</a>

					<a href="#" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Sign out</a>

				</div>

			</div>
			-->
		</nav>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Profile from './Profile.vue';
import MapSearch from './MapSearch.vue';
import AuthManager from '../../../lib/AuthManager';
import Auth from './Auth.vue';
import Signer2 from '@/lib/Signer2';

export default defineComponent({
	components: { Auth, MapSearch, Profile },

	props: {
		menuItems: Array,
	},

	data() {
		return {
			homepageUrl: import.meta.env.VITE_HOMEPAGE_URL || '/',
			open: false,
		};
	},

	setup() {
		return {
			AuthManager,
			Signer2,
		};
	},

	methods: {
		onMenuItemClick($event: any, navigate: Function) {
			// Close
			this.open = false;

			// Delegate
			return navigate($event);
		},
	},
});
</script>
