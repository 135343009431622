<template>
	<div class="flex items-center pr-2 sm:static sm:inset-auto xl:pr-0" data-testid="layout-topBar-Profile">
		<transition
			enter-active-class="duration-300 ease-out"
			enter-from-class="transform opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="duration-200 ease-in"
			leave-from-class="opacity-100"
			leave-to-class="transform opacity-0"
		>
			<div class="ml-auto mr-3 flex items-center">
				<button
					v-if="isCorrectNetwork === false"
					class="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800"
					@click="switchToCorrectNetwork"
				>
					Wrong network!
				</button>
			</div>
		</transition>

		<!--
		<router-link :to="'/profile/credits'">
			<div
				class="mr-2 h-[2.75rem] inline-flex items-center rounded-full bg-econeonblue py-2 pl-2.5 pr-2.5 transition hover:bg-opacity-75 sm:pl-5"
				data-testid="credits"
			>
				<div class="text-center text-sm font-bold text-white" data-testid="value">
					{{ vueNumberFormatTs(credits) }}
				</div>

				<div class="ml-2 mr-4 block hidden text-xs text-white opacity-75 sm:block">Credits</div>

				<svg
					class="hidden sm:block"
					xmlns="http://www.w3.org/2000/svg"
					width="26"
					height="26"
					viewBox="0 0 26 26"
				>
					<g id="Group_1584" data-name="Group 1584" transform="translate(-1480 -19)">
						<circle
							id="Ellipse_538"
							data-name="Ellipse 538"
							cx="13"
							cy="13"
							r="13"
							transform="translate(1480 19)"
							fill="#fff"
						/>
						<path
							id="Path_14795"
							data-name="Path 14795"
							d="M9.361,4V14.723m5.361-5.361H4"
							transform="translate(1483.639 22.639)"
							fill="none"
							stroke="#1d5df9"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
					</g>
				</svg>
			</div>
		</router-link>
		-->

		<!--
		<button type="button" class="bg-ecogreen p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
			<span class="sr-only">View notifications</span>
			<svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
					  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
			</svg>
		</button>
		-->

		<div class="relative" v-click-away="() => (profileMenuIsOpen = false)" data-testid="profile">
			<div class="flex items-center">

				<button type="button"
						@click="profileMenuIsOpen = !profileMenuIsOpen"
						data-testid="dropdownToggle"
						class="inline-flex items-center rounded-full md:bg-[#2f4e96] hover:opacity-75 transition md:pl-4 md:pr-1.5 md:py-1 lg:pl-2 lg:py-1.5 xl:py-1 xl:pr-1.5 xl:pl-4">
					<span class="text-white font-medium md:text-base lg:text-xs xl:text-base mr-3 hidden md:block" data-testid="myAccountLabel">My account</span>
					<span class="flex lg:w-[2rem] lg:h-[2rem] xl:h-[2.5rem] xl:w-[2.5rem] md:h-[2.25rem] md:w-[2.25rem] items-center relative justify-center rounded-full bg-gray-100 text-sm transition focus:outline-none">
						<img src="/img/common/ecolands_avatar.svg" class="w-10 h-10 md:h-12 md:w-12 lg:w-8 lg:h-8 xl:w-12 xl:h-12" alt="Icon"/>
					</span>
				</button>
			</div>

			<!-- TODO Medium: Fix, not recognized by vue-tsc. -->
			<!-- enter-class="transform opacity-0 scale-95" -->
			<!-- leave-class="transform opacity-100 scale-100" -->
			<transition name="slide-over">
				<div
					class="absolute right-0 z-20 mt-2 w-[235px] origin-top-right rounded-b-xl rounded-t-2xl bg-white pb-1 shadow-xl ring-1 ring-gray-600 ring-opacity-5 focus:outline-none"
					role="menu"
					v-if="profileMenuIsOpen"
					tabindex="-1"
				>
					<!--
					<router-link
						to="/profile/me"
						class="block px-4 py-2 text-sm text-gray-700 transition-opacity hover:opacity-75"
						linkActiveClass="bg-gray-100"
						role="menuitem"
						tabindex="-1"
						@click="profileMenuIsOpen = false">
						Your Profile
					</router-link>
					-->

					<router-link
						to="/profile/credits"
						class="flex items-center justify-between px-6 py-3 transition-opacity hover:bg-blue-700 bg-econeonblue rounded-t-xl"
						linkActiveClass="bg-gray-100"
						role="menuitem"
						tabindex="-1"
						data-testid="credits"
						@click="profileMenuIsOpen = false"
					>
						<div class="inline-flex items-center space-x-1 text-white">
							<span class="text-sm" data-testid="creditsValue">{{ credits }}</span>
							<div class="block text-sm text-white/70">Credits</div>
						</div>

<!--						<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 rounded-full text-econeonblue" width="24" height="24" viewBox="0 0 24 24">-->
<!--							<path id="circle-plus-solid" d="M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Zm-1.125-7.875v-3h-3a1.125,1.125,0,1,1,0-2.25h3v-3a1.125,1.125,0,1,1,2.25,0v3h3a1.125,1.125,0,0,1,0,2.25h-3v3a1.125,1.125,0,0,1-2.25,0Z" fill="#fff"/>-->
<!--						</svg>-->
					</router-link>

					<router-link
						to="/profile/settings"
						class="mx-2 my-1 flex items-center justify-between rounded-2xl px-4 py-3 transition-opacity hover:bg-gray-100"
						linkActiveClass="bg-gray-100"
						role="menuitem"
						tabindex="-1"
						data-testid="settings"
						@click="profileMenuIsOpen = false"
					>
						<i class="fal fa-user-circle fa-lg text-gray-800"></i>

						<div class="block text-base text-gray-800">Settings</div>
					</router-link>

					<router-link
						to="/profile/portfolio"
						class="mx-2 my-1 flex items-center justify-between rounded-2xl px-4 py-3 transition-opacity hover:bg-gray-100"
						linkActiveClass="bg-gray-100"
						role="menuitem"
						tabindex="-1"
						data-testid="portfolio"
						@click="profileMenuIsOpen = false"
					>
						<i class="fal fa-hexagon fa-lg text-gray-800"></i>

						<div class="block text-base text-gray-800">My virtual land</div>
					</router-link>

					<router-link
						to="/profile/certificates"
						class="mx-2 my-1 flex items-center justify-between rounded-2xl px-4 py-3 transition-opacity hover:bg-gray-100"
						linkActiveClass="bg-gray-100"
						role="menuitem"
						tabindex="-1"
						data-testid="certificates"
						@click="profileMenuIsOpen = false"
					>
						<i class="fal fa-leaf fa-lg text-gray-800"></i>

						<div class="block text-base text-gray-800">My CO2 certificates</div>
					</router-link>

					<!--
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 transition-opacity hover:opacity-75" role="menuitem" tabindex="-1">Settings</a>
                    -->
					<router-link
						to="/profile/referral"
						class="mx-2 my-1 flex items-center justify-between rounded-2xl px-4 py-3 transition-opacity hover:bg-gray-100"
						linkActiveClass="bg-gray-100"
						role="menuitem"
						tabindex="-1"
						data-testid="referral"
						@click="profileMenuIsOpen = false"
						v-if="false"
					>

						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19.199" viewBox="0 0 18 19.199" class="text-gray-800 ml-1">
							<path id="hand-wave-light"
								  d="M13.311,3.638a.6.6,0,0,0,.51-.679l-.045-.319A3,3,0,0,0,11.23.094L10.911.053a.6.6,0,1,0-.169,1.189l.319.045a1.8,1.8,0,0,1,1.526,1.526l.045.319a.605.605,0,0,0,.679.51ZM.538,13.854a.6.6,0,0,0-.506.679l.045.319A3,3,0,0,0,2.623,17.4l.319.045a.6.6,0,0,0,.169-1.189l-.319-.045a1.8,1.8,0,0,1-1.526-1.526l-.045-.319a.6.6,0,0,0-.679-.51ZM5.665,2.386A2.1,2.1,0,0,0,2.837,5.213a2.13,2.13,0,0,0-.889.529,2.1,2.1,0,0,0,0,2.97l.356.356a2.1,2.1,0,0,0-.791,3.461l4.62,4.658a6.953,6.953,0,0,0,11.892-4.894v-.518h0V11.75h0v-6a2.1,2.1,0,1,0-4.2,0v.413L9.164,1.5a2.1,2.1,0,0,0-3.5.889ZM4.075,3.62a.9.9,0,0,1,1.271,0l.33.33,0,0L10.825,9.1a.6.6,0,0,0,.848-.848L7.041,3.62l0,0A.9.9,0,0,1,8.312,2.344L14,8.03a.6.6,0,0,0,1.024-.424V5.746a.9.9,0,1,1,1.8,0v6h0v.026h0v.518a5.754,5.754,0,0,1-9.841,4.054L2.372,11.682a.9.9,0,0,1,1.283-1.275L5,11.757l0,0L6.587,13.34a.6.6,0,0,0,.848-.848L5.852,10.91,3.985,9.046l0,0L2.8,7.861A.9.9,0,0,1,4.067,6.586L8.7,11.217a.6.6,0,1,0,.848-.848L7.806,8.626l0,0L4.075,4.891a.9.9,0,0,1,0-1.271Z"
								  transform="translate(-0.025 -0.047)"/>
						</svg>

						<div class="block text-base text-gray-800">Invite a friend</div>

					</router-link>

					<div class="my-2 border-t border-gray-200"></div>

					<div
						class="mx-2 my-1 flex cursor-pointer items-center justify-between rounded-2xl px-4 py-3 transition-opacity hover:bg-gray-100"
						role="menuitem"
						tabindex="-1"
						@click="logout"
					>
						<i class="fal fa-sign-out fa-lg text-gray-800"></i>

						<span class="block text-base text-gray-800"> Sign out </span>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {vueNumberFormatTs} from '@/lib/vueNumberFormatTs';
import Signer2 from '@/lib/Signer2';
import AuthManager from '@/lib/AuthManager';
import Decimal from 'decimal.js';

export default defineComponent({
	data: () => ({
		profileMenuIsOpen: false,
	}),

	setup() {
		return {
			isCorrectNetwork: Signer2.reactiveIsCorrectNetwork,
			switchToCorrectNetwork: async () => await Signer2.switchToCorrectNetwork(),
			vueNumberFormatTs,
		};
	},

	methods: {
		logout() {
			// Logout now.
			AuthManager.logout();

			// Close menu.
			this.profileMenuIsOpen = false;
		},
	},

	computed: {
		profile() {
			return AuthManager.reactiveProfile.value;
		},

		credits() {
			return this.profile ? new Decimal(this.profile.creditsTotal) : new Decimal(0);
		},
	},
});
</script>

<style lang="scss" scoped>
.slide-over-enter-active,
.slide-over-active {
	@apply scale-100 transform opacity-100 transition duration-100 ease-out;
}

.slide-over-enter-from,
.slide-over-leave-to {
	@apply scale-95 transform opacity-0 transition duration-75 ease-in;
}
</style>
