import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import VueClickAway from 'vue3-click-away';
import VueLoaders from 'vue-loaders';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import notiwind from 'notiwind';
import VueNumberFormat from 'vue-number-format';
import {vLongpress} from '@nanogiants/vue3-longpress';
import {autoAnimatePlugin} from '@formkit/auto-animate/vue';

// @ts-ignore
import {longClickDirective} from 'vue-long-click';

import AuthManager from './lib/AuthManager';
import routes from './routes';
import TestRegistry from '@/lib/TestRegistry';
import EngineApi from '@/lib/EngineApi';
// import Signer2 from '@/lib/Signer2';
import PrelaunchManager from '@/lib/PrelaunchManager';
import App from './components/App.vue';
import OfferManager from '@/components/pages/map/lib/OfferManager';

import 'vue-loaders/dist/vue-loaders.css';
import './main.css';
import initGoogleTagManager from '@/googleTagManager';
import Testing from '@/lib/Testing';
import LoadScript from 'vue-plugin-load-script';
import {pageTitle} from "vue-page-title";
import {Settings} from "luxon";

// @note Did not work in env.d.ts
declare module '@vue/runtime-dom' {
	interface HTMLAttributes {
		dataTestid?: string;
		dataTestidExtra?: string;
		dataType?: string; // For use of particle.js in Header.vue
	}

	interface SVGAttributes {
		xDescription?: string;
		fillRule?: string;
		strokeLinecap?: string;
		strokeLinejoin?: string;
		clipRule?: string;
		strokeWidth?: string;
	}
}

// Init Vue app.
let app = createApp(App);

// Init Vue router.
const router = createRouter({
	history: createWebHistory('/'),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return {top: 0};
	},
});

router.beforeEach((from, next) => {
	if (Testing.isTesting) {
		console.info('Routing', from?.fullPath, next?.fullPath);
	}
});

// Init Sentry?
if (import.meta.env.VITE_SENTRY_DSN)
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_APP_ENV,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: [document.location.host, /^\//],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.2,
		ignoreErrors: ['ResizeObserver loop limit exceeded'],
		beforeSend(event) {

			// @ugly Refresh page due to client probably does not have the latest version of the assets?
			let message = event?.exception?.values?.[0]?.value;

			if (message && (message.includes('Failed to fetch dynamically imported module') || message.includes('Load failed') || message.includes('Importing a module script failed') || message.includes('Unable to preload CSS for'))) {

				// Refresh because we did not in the last minute? This avoids infinite loops.
				let lastRefresh = window.localStorage.getItem('main.lastRefreshDueToImportModuleFailed');

				if (!lastRefresh || parseInt(lastRefresh) < (new Date()).getTime() - 60 * 1000) {

					// Store last refresh time.
					window.localStorage.setItem('main.lastRefreshDueToImportModuleFailed', '' + (new Date()).getTime());

					// Reload window to get latest assets.
					window.location.reload();

					// Sent nothing to Sentry.
					return null;
				}
			}

			// Sent to Sentry.
			return event;
		},
	});

// Init Vue plugins.
app.use(notiwind);
app.use(VueNumberFormat, {prefix: '', decimal: '.', thousand: ',', precision: 0});
app.use(router);
app.use(VueClickAway);
app.directive('use-longpress', vLongpress);
app.use(autoAnimatePlugin);

// TODO High: Remove since it uses gifs and is too annoying to see.
app.use(VueLoaders);

app.use(LoadScript);

app.use(pageTitle({ router }));

app.mount('#app');

// Init testing.
Testing.init();

TestRegistry.setMultiple({
	axios,
	router,
	'luxonSettings': Settings,
	'lib.AuthManager': AuthManager,
	'lib.EngineApi': EngineApi,
	'lib.PrelaunchManager': PrelaunchManager,
	// 'lib.Signer2': Signer2,
	'pages.map.lib.OfferManager': OfferManager,
});

// Init managers.
(async () => {
	// await Signer2.init();
	await AuthManager.init();
})();

initGoogleTagManager();
