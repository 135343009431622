<template>

	<div
		class="w-full bg-ecoblue border-b border-white border-opacity-20 z-50"
		data-testid="layout-topBar-DesktopNavigation">

		<div
			class="max-w-7xl mx-auto px-4 w-full"
			data-testid="layout-topbar-DesktopNavigation">
			<div class="relative flex items-center justify-between h-16">

				<div class="flex items-center justify-center sm:items-stretch sm:justify-start flex-shrink-0 mr-auto">

					<!-- Logo -->
					<div class="flex-shrink-0 flex items-center">

						<router-link
							to="/"
							class="hover:opacity-75 transition-opacity">
							<img src="/img/common/logo-2022-def-white.svg" class="h-8 hidden xl:block xl:-ml-2"/>
						</router-link>

						<router-link
							to="/"
							class="hover:opacity-75 transition-opacity">
							<img src="/img/common/icon-with-gradients.svg" class="h-8 block xl:hidden xl:-ml-2"/>
						</router-link>

					</div>

					<!-- Menu items -->
					<div class="sm:ml-6">
						<div
							class="flex space-x-4"
							data-testid="menu">

							<router-link
								v-for="menuItem of menuItems"
								:to="(menuItem as any).to"
								v-slot="{ href, route, navigate, isActive, isExactActive }"
								custom
							>
								<a
									v-if="(menuItem as any).hasOwnProperty('subItems')"
									class="nav-item relative px-3 py-2 rounded-md text-sm font-medium transition-all select-none"
									:data-testid="'topBar-' + menuItem.to.slice(1)"
									:class="isActive || menuItemIsActive(menuItem) ? 'bg-ecogreen text-white hover:bg-opacity-75' : 'text-white hover:text-ecogreen'"
									:href="href"
									@click="navigate"

									@mouseenter="shownDropdown = menuItem.label"
									@mouseleave="shownDropdown = null"
								>
									<span> {{ menuItem.label }} </span>

									<div
										v-show="shownDropdown == menuItem.label"
										class="top-[calc(100%+.5rem)] -left-3 bg-white flex flex-col absolute w-48 rounded-xl pb-1 shadow-xl ring-1 ring-gray-600 ring-opacity-5 focus:outline-none gap-y-2"
									>
										<router-link
											v-for="subMenuItem of menuItem.subItems"
											:to="subMenuItem.to"
											v-slot="{ href, route, navigate, isActive, isExactActive }"
											custom
										>
											<a
												class="text-gray-800 px-3 py-2 rounded-md text-sm font-medium transition-all first:mt-3 last:mb-3 mx-3"
												:class="isActive || menuItemIsActive(subMenuItem) ? '!text-white bg-ecogreen' : 'hover:bg-gray-100'"
												:data-testid="'topBar-' + subMenuItem.to.slice(1)"
												:href="href"
												@click="navigate"
											>
												{{ (subMenuItem as any).label }}
											</a>
										</router-link>
									</div>
								</a>

								<a
									v-else-if="!(menuItem as any).to.includes('://')"
									class="px-3 py-2 rounded-md text-sm font-medium transition-all"
									:class="isActive || menuItemIsActive(menuItem) ? 'bg-ecogreen text-white hover:bg-opacity-75' : 'text-white hover:text-ecogreen'"
									:href="href"
									@click="navigate">
									{{ (menuItem as any).label }}
								</a>

								<a
									v-else
									class="px-3 py-2 rounded-md text-sm font-medium transition-all"
									:class="isActive || menuItemIsActive(menuItem) ? 'bg-ecogreen text-white hover:bg-opacity-75' : 'text-white hover:text-ecogreen'"
									:href="(menuItem as any).to"
									target="_blank">
									{{ (menuItem as any).label }}
								</a>
							</router-link>

						</div>
					</div>

				</div>

				<div class="w-full inline-flex items-center lg:space-x-0 xl:space-x-4">

					<!-- Search -->
					<MapSearch
						class="ml-auto w-full max-w-[300px]"/>

					<!-- Profile -->
					<div
						class="hidden lg:flex flex-col ml-auto">

						<Profile
							v-if="AuthManager.reactiveIsLoggedIn.value"
							class="absolute inset-y-0 right-0 sm:ml-6 lg:ml-1 sm:pr-0 pr-2"/>

						<Auth
							class="ml-auto"
							v-else/>

					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script lang="ts">

import {defineComponent} from 'vue';
import Profile from './Profile.vue';
import MapSearch from './MapSearch.vue';
import AuthManager from '../../../lib/AuthManager';
import Auth from './Auth.vue';
import Signer2 from '@/lib/Signer2';

export default defineComponent({

	components: { Auth, MapSearch, Profile },

	props: {
		menuItems: Array
	},

	data() {
		return {
			shownDropdown: null
		};
	},

	setup() {
		return {
			homepageUrl: import.meta.env.VITE_HOMEPAGE_URL || '/',
			AuthManager,
			Signer2,
		};
	},

	methods: {

		menuItemIsActive(menuItem: any) {

			// Direct hit?
			if (this.$route.path.startsWith(menuItem.to))
				return true;

			// Test matched routes, this is to support dialog routes.
			for (let matchedRoute of this.$route.matched) {
				if ((matchedRoute as any).currentDefaultPath?.startsWith(menuItem.to))
					return true;
			}

			// One of its subItems is active?
			const subItemOfMenuItemIsActive = menuItem.subItems?.find((subItem: any) => subItem.to == this.$route.path)

			if(subItemOfMenuItemIsActive)
				return true;

			// No hit found.
			return false;
		}

	}

})

</script>

<style scoped lang="scss">

.nav-item::before {
	@apply absolute left-0 -bottom-3 right-0 h-3;
	content: '';
}
</style>
