export default function initGoogleTagManager() {

	// Init GA.
	(window as any).dataLayer = (window as any).dataLayer || [];

	function gtag() {
		(window as any).dataLayer.push(arguments);
	}

	(window as any).gtag = gtag;

	// Start for real?
	if (import.meta.env.VITE_APP_ENV === 'production') {

		// @ts-ignore
		gtag('js', new Date());

		// @ts-ignore
		gtag('config', 'G-5E1042HXMQ');

		var head = document.getElementsByTagName('head')[0];
		var js = document.createElement('script');
		js.src = 'https://www.googletagmanager.com/gtag/js?id=G-5E1042HXMQ';
		head.appendChild(js);
	}

}
