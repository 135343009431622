<template>
	<footer class="border-t bg-white">
		<div>
			<div class="mx-auto max-w-6xl py-8 px-4 sm:px-6 lg:px-8">

				<div class="inline-flex justify-between w-full items-center flex-wrap gap-8">

					<img
						src="/img/common/ecolands_logo_var2.svg"
						class="h-12 w-auto drop-shadow"
						alt="Ecolands Logo"
						loading="lazy"
					/>

					<ul role="list" class="inline-flex gap-x-10 gap-y-4 flex-wrap">
						<li class="">
							<a href="/" class="text-sm text-gray-500 transition hover:opacity-75"> Home </a>
						</li>

						<li class="">
							<a href="/map" class="text-sm text-gray-500 transition hover:opacity-75"> Map </a>
						</li>

						<!-- Disabled on client's request.
						<li class="">
							<a href="/compensate-now" class="text-sm text-gray-500 transition hover:opacity-75"> Compensate now </a>
						</li>
						-->

						<li class="">
							<a href="/virtual-land" class="text-sm text-gray-500 transition hover:opacity-75"> Virtual land </a>
						</li>

						<li class="">
							<a href="/licenses" class="text-sm text-gray-500 transition hover:opacity-75"> Open Source </a>
						</li>

						<li class="">
							<a :href="'/docs/Terms-of-Use-v' + config.termsVersion + '.pdf'" class="text-sm text-gray-500 transition hover:opacity-75" download> Terms of use </a>
						</li>

						<li class="">
							<a href="/docs/Privacyverklaring_(ENG).pdf" class="text-sm text-gray-500 transition hover:opacity-75" data-testid="privacyStatement" download> Privacy statement </a>
						</li>

						<li class="">
							<a href="/docs/Disclaimer_(ENG).pdf" class="text-sm text-gray-500 transition hover:opacity-75" data-testid="disclaimer" download> Disclaimer </a>
						</li>

					</ul>

				</div>

			</div>

			<div class="border-t mb-10">
				<div class="flex flex-wrap items-center justify-between mx-auto max-w-6xl px-4 py-6 sm:px-6 lg:px-8" data-testid="footerBottom">
					<div class="w-full lg:w-1/6 hidden lg:flex">
						<div class="flex items-center">
							<span class="text-xs text-gray-500 md:mt-0">
								&copy; ecolands {{ copyrightYear }}
							</span>
						</div>
					</div>
					<div class="w-full lg:w-5/6">
						<div class="flex flex-wrap lg:flex-nowrap lg:justify-end items-center lg:space-x-8">
<!--							<div class="flex items-center lg:ml-6 w-full flex-wrap lg:flex-nowrap">-->
<!--								<div class="lg:mr-8 mb-4 lg:mb-0">-->
<!--									<span class="text-xl text-gray-900 font-semibold -mt-1.5">Subscribe to <span class="text-xl text-ecogreen font-semibold -mt-1.5">newsletter</span></span>-->
<!--								</div>-->

<!--								<NewsletterSignup :footer="true" class="lg:mr-8 w-full lg:w-auto mb-8 lg:mb-0"/>-->
<!--							</div>-->

							<div class="lg:hidden flex items-center mr-4">
								<div class="flex items-center">
									<span class="text-xs text-gray-500 md:mt-0">
										&copy; ecolands {{ copyrightYear }}
									</span>
								</div>
							</div>
							<div v-if="false" class="flex items-center space-x-4 ml-auto lg:w-40 xl:w-auto">
								<a
									href="https://www.linkedin.com/company/ecolands/"
									target="_blank"
									class="text-black transition-opacity hover:opacity-50 text-black"
								>
									<span class="sr-only">LinkedIn</span>
									<i class="text-2xl fab fa-linkedin"></i>
								</a>

								<a
									href="https://www.facebook.com/ecolands.io"
									target="_blank"
									class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"
								>
									<span class="sr-only">Facebook</span>
									<svg fill="white" viewBox="0 0 320 512" class="h-5 w-5">
										<path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
									</svg>
								</a>

								<a
									href="https://www.instagram.com/ecolands_io/"
									target="_blank"
									class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"
								>
									<span class="sr-only">Instagram</span>
									<svg fill="white" viewBox="0 0 448 512" class="h-5 w-5">
										<path
											d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
									</svg>
								</a>

								<a
									href="https://twitter.com/ecolands__io"
									target="_blank"
									class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"
								>
									<span class="sr-only">Twitter</span>
									<svg class="h-5 w-5" fill="white" viewBox="0 0 24 24">
										<path
											d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
										/>
									</svg>
								</a>

								<a
									href="https://discord.gg/Vjc3Qch5SC"
									target="_blank"
									class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"
								>
									<span class="sr-only">Discord</span>
									<svg class="h-5 w-5" fill="white" viewBox="0 0 640 512">
										<path
											d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"
										/>
									</svg>
								</a>
							</div>
						</div>
					</div>
				</div>

<!--				<div class="mx-auto flex max-w-6xl items-center justify-between px-4 py-6 sm:px-6 lg:px-8">-->
<!--					<p class="text-xs text-gray-500 md:mt-0">&copy; Ecolands {{ copyrightYear }}</p>-->

<!--					<div class="flex">-->

<!--						<div class="flex items-center">-->
<!--							<div>-->
<!--								<span class="text-2xl text-gray-500 font-semibold -mt-1.5">Subscribe to <span class="text-2xl text-ecogreen font-semibold -mt-1.5">newsletter</span></span>-->
<!--							</div>-->

<!--							<NewsletterSignup-->
<!--								class="w-full"/>-->
<!--						</div>-->

<!--						<div class="flex items-center space-x-4">-->
<!--							<a-->
<!--								href="https://www.linkedin.com/company/ecolands/"-->
<!--								target="_blank"-->
<!--								class="text-black transition-opacity hover:opacity-50 text-black"-->
<!--							>-->
<!--								<span class="sr-only">LinkedIn</span>-->
<!--								<i class="text-2xl fab fa-linkedin"></i>-->
<!--							</a>-->

<!--							<a-->
<!--								href="https://www.facebook.com/ecolands.io"-->
<!--								target="_blank"-->
<!--								class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"-->
<!--							>-->
<!--								<span class="sr-only">Facebook</span>-->
<!--								<svg fill="white" viewBox="0 0 320 512" class="h-5 w-5">-->
<!--									<path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>-->
<!--								</svg>-->
<!--							</a>-->

<!--							<a-->
<!--								href="https://www.instagram.com/ecolands_io/"-->
<!--								target="_blank"-->
<!--								class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"-->
<!--							>-->
<!--								<span class="sr-only">Instagram</span>-->
<!--								<svg fill="white" viewBox="0 0 448 512" class="h-5 w-5">-->
<!--									<path-->
<!--										d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />-->
<!--								</svg>-->
<!--							</a>-->

<!--							<a-->
<!--								href="https://twitter.com/ecolands__io"-->
<!--								target="_blank"-->
<!--								class="text-black brightness-100 invert filter transition-opacity hover:opacity-50"-->
<!--							>-->
<!--								<span class="sr-only">Twitter</span>-->
<!--								<svg class="h-5 w-5" fill="white" viewBox="0 0 24 24">-->
<!--									<path-->
<!--										d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"-->
<!--									/>-->
<!--								</svg>-->
<!--							</a>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
			</div>
		</div>
	</footer>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import * as _ from 'lodash';
import NewsletterSignup from '@/components/common/NewsletterSignup.vue';
import config from '@/config';

export default defineComponent({

	components: {NewsletterSignup},

	setup() {
		return {
			config,
			// explorerUrl: Signer2.getExplorerUrl(),
		};
	},

	data() {
		return {
			domainNftContractAddress: null as string | null
		};
	},

	async mounted() {
		// TODO Critical: Implement?
		//this.domainNftContractAddress = (await getContract('EcolandsDomainBundleNFT', false)).address;
	},

	computed: {

		copyrightYear(): Number {
			return new Date().getFullYear();
		},

	}
});
</script>
