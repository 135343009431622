<template>

	<button
		class="lg:px-6 md:py-2 rounded-full bg-ecoheaderblue h-[2.75rem] w-[2.75rem] lg:w-full text-sm font-bold text-white transition-opacity md:bg-ecogreen hover:opacity-75"
		data-testid="layout-topBar-Auth"
		@click="login">
		<span class="hidden md:block">Log in</span>
		<span class="block md:hidden mt-0.5"><i class="fal fa-sign-in text-white fa-lg"></i></span>
	</button>

</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({

	methods: {

		login() {
			this.$router.push('/profile/auth');
		}

	}

})

</script>
