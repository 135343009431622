<template>

	<div
		class="min-h-full w-full absolute inset pt-16 pb-12 flex flex-col bg-white z-40">
		<main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
			<div class="flex-shrink-0 flex justify-center">
				<a href="/" class="inline-flex">
					<span class="sr-only">Coming back soon!</span>
				</a>
			</div>
			<div class="py-16">
				<div class="text-center">
					<p class="text-sm font-semibold text-green-600 uppercase tracking-wide"></p>
					<h1 class="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">Maintenance</h1>
					<p class="mt-2 text-base text-gray-500">
						We are back <span
						v-use-longpress="1000"
						@longpress="getAccess">soon</span>
					</p>
					<div class="mt-6">
						<a href="https://www.ecolands.io" class="text-base font-medium text-green-600 hover:text-green-500">Go to home<span aria-hidden="true"> &rarr;</span></a>
					</div>
				</div>
			</div>
		</main>
	</div>

</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({

	emits: ['update:modelValue'],

	props: {
		modelValue: Boolean,
	},

	methods: {

		getAccess() {
			this.$emit('update:modelValue', false);
		},

	},

});

</script>
