<template>

	<div
		data-testid="layout-topBar-MapSearch"
		class="relative focus-within:text-gray-400 z-20">

		<div class="z-10 absolute inset-y-0 left-3 pl-3 md:items-center pointer-events-none hidden md:flex text-white/75">
			<svg id="Group_391" data-name="Group 391" xmlns="http://www.w3.org/2000/svg"
				 xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="19.826" viewBox="0 0 24 19.826">
				<defs>
					<clipPath id="clip-path-search">
						<rect id="Rectangle_16" data-name="Rectangle 16" width="24" height="19.826"/>
					</clipPath>
				</defs>
				<g id="Group_7" data-name="Group 7" transform="translate(0 0)" clip-path="url(#clip-path-search)">
					<path id="Path_18" data-name="Path 18"
						  d="M21.629,10.789,17.5,9.757a13.717,13.717,0,0,1-.684,1.442l4.434,1.108a1.566,1.566,0,0,1,1.186,1.519V16.6a1.565,1.565,0,0,1-1.186,1.518L12.38,20.335a1.586,1.586,0,0,1-.76,0L2.751,18.118A1.565,1.565,0,0,1,1.565,16.6V13.827a1.566,1.566,0,0,1,1.186-1.519L7.185,11.2A13.717,13.717,0,0,1,6.5,9.757l-4.13,1.032A3.126,3.126,0,0,0,0,13.827V16.6a3.126,3.126,0,0,0,2.371,3.037l8.869,2.217a3.144,3.144,0,0,0,1.519,0l8.869-2.217A3.126,3.126,0,0,0,24,16.6V13.827a3.126,3.126,0,0,0-2.371-3.037"
						  transform="translate(0 -2.121)" fill="currentColor"/>
					<path id="Path_19" data-name="Path 19"
						  d="M13.934,0H13.9A5.159,5.159,0,0,0,8.744,5.157a6.421,6.421,0,0,0,.518,2.332A11.543,11.543,0,0,0,9.84,8.706c.164.3.336.6.52.884a23.591,23.591,0,0,0,2.813,3.515,1.028,1.028,0,0,0,.729.3h.012a1.024,1.024,0,0,0,.717-.3,23.591,23.591,0,0,0,2.813-3.515c.184-.287.357-.584.52-.884a11.383,11.383,0,0,0,.577-1.217,6.421,6.421,0,0,0,.518-2.332A5.16,5.16,0,0,0,13.934,0m1.953,8.188c-.059.1-.12.2-.181.291A20.374,20.374,0,0,1,13.948,10.8l-.047.056A20.748,20.748,0,0,1,12.1,8.479c-.061-.1-.121-.193-.181-.291a10.9,10.9,0,0,1-.642-1.2,4.574,4.574,0,0,1-.466-1.83A3.1,3.1,0,0,1,13.9,2.063h.02a3.1,3.1,0,0,1,3.074,3.094,4.586,4.586,0,0,1-.466,1.83,10.91,10.91,0,0,1-.643,1.2"
						  transform="translate(-1.901 0)" fill="currentColor"/>
				</g>
			</svg>
		</div>

		<div
			class="search"
			:class="{filled: searchFilled}"
			ref="search"/>

	</div>

</template>

<script lang="ts">

import {defineComponent} from "vue";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {goToMapPlace} from "../../pages/map/goToMap";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import config from "@/config";

export default defineComponent({

	data() {
		return {
			geocoder: new MapboxGeocoder({
				accessToken: config.mapbox.apiToken,
				types: ["country", "region", "postcode", "district", "place", "locality", "neighborhood", "address", "poi", "poi.landmark"].join(','),
				placeholder: 'Search for your favorite spot',
				limit: 10,
			}),
			searchFilled: false,
		};
	},

	mounted() {
		this.initGeocoder();
	},

	methods: {

		initGeocoder() {

			// Start
			this.geocoder.addTo(this.$refs['search'] as HTMLElement);

			// Listen for interaction to determine how to style the input.
			let that = this;

			((this.$refs['search'] as HTMLElement).querySelector('input') as HTMLInputElement).addEventListener('input', function (this: HTMLInputElement) {
				that.searchFilled = !!this.value;
			});

			this.geocoder.on('clear', () => {
				that.searchFilled = false;
			});

			// Listen for results.
			// Add geocoder result to container.
			this.geocoder.on('result', (e: any) => goToMapPlace(this.$router, e.result));
		},

	}

})

</script>

<style lang="scss" scoped>

// @ugly
.search {

	:deep(.mapboxgl-ctrl-geocoder--icon) {
		display: none !important;
	}

	:deep(.mapboxgl-ctrl-geocoder.mapboxgl-ctrl) {
		@apply bg-transparent w-auto border-none min-w-0;
		box-shadow: none !important;

		@screen md {
			@apply border-none bg-none;
		}
	}

	:deep(.mapboxgl-ctrl-geocoder--input) {
		@apply block w-full bg-ecoheaderblue border-transparent py-2 pr-5 pl-4 leading-5 h-[2.75rem] md:pl-16 xl:pr-5
		placeholder-white/75 focus:outline-none focus:ring-0 focus:border-white focus:text-white/75 text-sm rounded-full;
	}

	:deep(.mapboxgl-ctrl-geocoder--icon-search) {
		@apply hidden;

		@screen md {
			@apply inline-block;
			top: 10px;
		}
	}

	&.filled :deep(.mapboxgl-ctrl-geocoder--input) {
		@apply ring-0 border-white text-white placeholder-gray-400;
	}

	:deep(.mapboxgl-ctrl-geocoder--button) {
		background: none !important;;
	}

	:deep(.suggestions) {
		@apply overflow-y-auto max-h-[16rem] py-1;

		@screen md {
			@apply max-h-[32rem];
		}
	}

	:deep(.mapboxgl-ctrl-geocoder--suggestion) {
		@apply px-3 py-2;
	}

	:deep(.mapboxgl-ctrl-geocoder--suggestion-title) {
		@apply text-base font-bold text-ecogreen;
	}

	:deep(.mapboxgl-ctrl-geocoder--suggestion-address) {
		@apply text-sm text-gray-500 truncate;
	}
}

</style>
