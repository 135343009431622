import _ from "lodash";

export function closeRouteModal(vm: any) {
	vm.$router.push(getDefaultUrlOfModal(vm));
}

export function getDefaultUrlOfModal(vm: any) {

	// Get faked path - this is the path before the modal got opened.
	let route = _.find(vm.$route.matched, route => (route as any).currentDefaultPath);

	// Go to route?
	if (route) {
		return (route as any).currentDefaultPath;
	}
	// Go home?
	else {
		return '/';
	}
}
