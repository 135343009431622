<template>

	<div>

		<Modal/>

		<router-view
			v-if="isLoggedIn" />

	</div>

</template>

<script>

import Modal from "@/components/layout/Modal.vue";
import AuthManager from "@/lib/AuthManager";

export default {

	name: 'EmptyRouterView',

	components: {Modal},

	computed: {

		isLoggedIn() {
			return AuthManager.reactiveIsLoggedIn.value;
		}

	}

}

</script>
