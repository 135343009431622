import {ref, shallowRef} from "vue";
import {ethers, Signer} from "ethers";
import App from "@/components/App.vue";
import axios from "axios";
import EngineApi from "@/lib/EngineApi";
import {getContract} from "@/lib/Contracts";
import Testing from "@/lib/Testing";
import AuthManager from "@/lib/AuthManager";

// TODO Medium: Refactor, extract MetaMask and test Wallet to subclasses / adapters.
// TODO Medium: Rename to Signer
export default new class Signer2 {

	public reactiveRestoreDone = shallowRef<any>(false);
	public reactiveSigner = shallowRef<Signer | null>(null);
	public reactiveAddress = ref<string | null>(null);
	public reactiveIsCorrectNetwork = ref<boolean | null>(null);
	public reactiveConnectSignerPromise = ref<Promise<boolean> | undefined>(undefined);

	// @deprecated: web-3
	// @ts-ignore
	public providerRpc: ethers.providers.JsonRpcProvider;
	public providerWeb?: ethers.providers.JsonRpcProvider;

	constructor() {
		// @deprecated: web-3
		return;

		// Init providers.
		this.providerRpc = this.initProviderRpc();
	}

	public async init(): Promise<void> {

		if (Testing.isTesting) {

			if (AuthManager.testSeemToBeRemembered)
				await this.initProviderWebTest()

		} else
			this.initProviderWebMetamask();
	}

	protected initProviderRpc() {

		// Get RPC url from config.
		let rpcUrl = Testing.transformPortForJestTesting(import.meta.env.VITE_CHAIN_RPC);

		// Init JSON provider.
		return new ethers.providers.JsonRpcProvider(rpcUrl);
	}

	protected async initProviderWebTest() {

		// Abort due to no wallet mnemonic present?
		let requestedTestWallet = await (window as any).libSigner_testWallet?.();
		//let requestedTestWallet = {mnemonic: 'reject buffalo leaf athlete rabbit city attitude blush neck canal legal moral peace upon piece'};

		if (!requestedTestWallet)
			return;

		// Init wallet from name.
		let wallet = new ethers.Wallet(requestedTestWallet.pk);

		// Connect wallet with RPC provider.
		let provider = this.initProviderRpc();

		wallet = wallet.connect(provider);

		// TODO Medium: Is this correct? Is there a common type between the provider for MetaMask and a Wallet? Should this be JsonRpcProvider?
		// Set
		this.providerWeb = wallet as any;

		this.reactiveSigner.value = wallet as any;
		this.reactiveAddress.value = wallet.address;
	}

	protected initProviderWebMetamask(): ethers.providers.JsonRpcProvider | undefined {

		// Try to get a provider from the browser.
		let providerWeb = (window as any).web3?.currentProvider || (window as any).ethereum;

		if (!providerWeb)
			return;

		// Init provider.
		let provider = new ethers.providers.Web3Provider(providerWeb, 'any');

		// Listen for account change.
		provider.on('accountsChanged', () => /*await*/ this.managePossibleConnectedSignerMetamask());

		// Listen for network change.
		provider.on("network", (newNetwork: any, oldNetwork: any) => {

			// Determine if is correct network.
			this.reactiveIsCorrectNetwork.value = newNetwork?.chainId === parseInt(import.meta.env.VITE_CHAIN_ID);
		});

		// Set
		this.providerWeb = provider;
	}

	public async switchToCorrectNetwork() {

		// Get chain id.
		let chainId = '0x' + parseInt(import.meta.env.VITE_CHAIN_ID).toString(16);

		try {
			// Request to switch.
			await this.providerWeb!.send('wallet_switchEthereumChain', [
				{chainId}
			]);
		} catch (ex) {

			// 4902 indicates that the client does not recognize the Harmony One network
			if ((ex as any).code !== 4902) {
				throw ex;
			}

			// Request to add network.
			await this.providerWeb!.send('wallet_addEthereumChain', [
				{
					chainId,
					chainName: import.meta.env.VITE_CHAIN_NAME,
					rpcUrls: [import.meta.env.VITE_CHAIN_RPC],
					nativeCurrency: {
						name: import.meta.env.VITE_CHAIN_CURRENCY,
						symbol: import.meta.env.VITE_CHAIN_SYMBOL,
						decimals: import.meta.env.VITE_CHAIN_DECIMALS,
					},
					blockExplorerUrls: [this.getExplorerUrl()]
				}
			]);
		}

		// Good practice to reload.
		document.location.reload();
	}

	public async initTestArtifacts() {

		// Abort due to is not debugging?
		if (import.meta.env.VITE_APP_ENV !== 'local')
			return;

		// Abort due to already presented to add?
		if (window.localStorage.getItem('lib.Signer2.providedTestArtifacts'))
			return;

		// TODO Critical: fix
		// Notify
		App.getInstance().$notify({
			group: 'bottomRight',
			type: 'info',
			title: "Test tokens",
			text: 'You will receive test tokens!',
		});

		// Mint some tokens.
		axios.post(EngineApi.buildUrl('/app/debug/receive-test-tokens'), {
			walletAddress: this.reactiveAddress.value
		}).then();

		// Add test token to wallet.
		try {

			for (let contractName of ['TestPaymentToken', 'TestEcoCreditToken'/*, 'TestStakeableToken'*/]) {
				let contract = await getContract(contractName);

				(window as any).ethereum
					.request({
						method: 'wallet_watchAsset',
						params: {
							type: 'ERC20',
							options: {
								address: contract.address,
								symbol: await contract.symbol(),
								decimals: await contract.decimals(),
							},
						},
					});
			}
		} catch (error) {
		}

		// Set as provided.
		window.localStorage.setItem('lib.Signer2.providedTestArtifacts', '1');
	}

	public getExplorerUrl(): string {
		return (import.meta.env.VITE_CHAIN_EXPLORER as string).replace(/\/$/, '');
	}

	public async connectSigner(): Promise<boolean> {

		// Abort due to already connect?
		if (this.reactiveSigner.value)
			return true;

		// Connect signer, for testing or for Metamask?
		return Testing.isTesting ? await this.connectSignerTesting() : await this.connectSignerMetamask();
	}

	protected async connectSignerTesting(): Promise<boolean> {

		// Abort due to no wallet mnemonic present?
		let requestedTestWallet = await (window as any).libSigner_testWallet?.();
		//let requestedTestWallet = {mnemonic: 'reject buffalo leaf athlete rabbit city attitude blush neck canal legal moral peace upon piece'};

		if (!requestedTestWallet)
			return false;

		// Init wallet from name.
		let wallet = new ethers.Wallet(requestedTestWallet.pk);

		// Connect wallet with RPC provider.
		wallet = wallet.connect(this.providerRpc!);

		// Set
		this.reactiveSigner.value = wallet as any; //this.providerWeb!.getSigner();
		this.reactiveAddress.value = wallet.address; //await this.reactiveSigner.value.getAddress();

		this.providerWeb = wallet as any;

		//
		return true;
	}

	protected async connectSignerMetamask(): Promise<boolean> {
		// Abort due to already having an account?
		//if (this.providerWeb)
		//    return;

		// Connect due to no running signer promise?
		if (!this.reactiveConnectSignerPromise.value) {
			this.reactiveConnectSignerPromise.value = new Promise(async (resolve): Promise<void> => {

				try {

					// Request to show the user's accounts in its wallet.
					await this.requestProviderWebAccounts();

					// Return if we could get a signer.
					resolve(await this.managePossibleConnectedSignerMetamask());

				} finally {
					// After some time we give the user the change to reconnect.
					setTimeout(() => this.reactiveConnectSignerPromise.value = undefined, 2000);
				}
			});
		}

		// Await running promise
		return await this.reactiveConnectSignerPromise.value;
	}

	protected async requestProviderWebAccounts(): Promise<void> {
		try {

			// Request an account.
			await this.providerWeb!.send("eth_requestAccounts", []);
		} catch (ex) {

			console.error(ex);

			// TODO High: Handle user rejection.
			//if (ex?.code === 4001) {
		}
	}

	public async managePossibleConnectedSignerMetamask(): Promise<boolean> {

		// Abort due to using test wallet and we are not having auth data?
		if (Testing.isTesting && !AuthManager.testSeemToBeRemembered)
			return false;

		// Abort due to no web provider?
		if (!this.providerWeb)
			return false;

		// No accounts present?
		let accounts = await this.providerWeb!.listAccounts();

		if (accounts.length == 0) {

			// Clear signer and it's address.
			this.reactiveSigner.value = null;
			this.reactiveAddress.value = null;
		} else {
			// Get signer and it's address.
			this.reactiveSigner.value = this.providerWeb!.getSigner();
			this.reactiveAddress.value = await this.reactiveSigner.value.getAddress();

			// Init test artifacts.
			await this.initTestArtifacts();
		}

		// Return (not) connected.
		return this.reactiveSigner.value ? true : false;
	}

	public async getSigner(): Promise<Signer | null> {

		// Abort due to no signer connected?
		if (!await this.connectSigner())
			return null;

		// Return signer.
		return this.reactiveSigner.value;
	}

	public async getSignerAddress(): Promise<string | null> {

		// Abort due to no signer connected?
		if (!await this.connectSigner())
			return null;

		// Return signer address.
		return this.reactiveAddress.value;
	}

	// TODO High: Refactor, other class?
	public getRevertReason(exception: any): string | undefined {
		return exception?.data?.message?.split('reverted with reason string \'')?.[1]?.split("'")?.[0];
	}

}
