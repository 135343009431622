import {DateTime} from "luxon";
import AuthManager from "@/lib/AuthManager";
import Testing from "@/lib/Testing";
import { isMobile } from 'mobile-device-detect';

let northAmericaEnabled = async () => {

	// @ugly Just the existence of _test_config_NAEnabledSkipTestingCheck is enough, so we don't need to refactor to use promises.
	if (Testing.isTesting && (await (window as any)._test_config_NAEnabledSkipTestingCheck?.()) !== true)
		return true;

	else if (DateTime.now().toSeconds() >= DateTime.fromSQL('2023-02-16 14:00:00 Europe/Amsterdam').toSeconds())
		return true;

	else if (AuthManager.reactiveProfile.value && ['legal@ecolands.io', 'koen@ecolands.io', 'info@laveto.nl'].includes(AuthManager.reactiveProfile.value.email!))
		return true;

	else
		return false;
};

async function getMapboxStyleUrl()
{
	if(Testing.isTesting && (await (window as any)._test_config_mapboxBlackBackground?.())) {
		return 'mapbox://styles/ecolandsjeffrey/clepfypw300dv01msw26dpj3g';
	}

	return await northAmericaEnabled() ? 'mapbox://styles/ecolandsjeffrey/clbnpth50000014mwrzfeca3n' : 'mapbox://styles/ecolandsjeffrey/cl470ruz5000y15o0unmhbj1w'
}

function disabledDomainNftHoverState()
{
	return Testing.isTesting && getLocalStorageTestConfigValue('disableDomainNftHoverState') === 'true'
}

function multiSelectEnabled()
{
	return Testing.isTesting && getLocalStorageTestConfigValue('enableMultiSelect') === 'true'
}

function disabledResettingTheHoverStateWhenUnhovering()
{
	return Testing.isTesting && getLocalStorageTestConfigValue('disableResettingTheHoverStateWhenUnhovering') === 'true'
}

function enabledCustomDomainNftHexagonSelectorColor()
{
	return Testing.isTesting && getLocalStorageTestConfigValue('disableCustomDomainNftHexagonSelectorColor') !== 'true'
}

function getLocalStorageTestConfigValue(value: string)
{
	return (window as any).localStorage.getItem('_test_config_' + value)
}

export default {

	mapbox: {
		apiToken: 'pk.eyJ1IjoiZWNvbGFuZHNqZWZmcmV5IiwiYSI6ImNremJmZzE3dTFvaHEybm45bGg3ZWd3ejUifQ.3WailX-LJPsfLwqdfVxQXA',
		styleUrl: async () => await getMapboxStyleUrl(),
		disabledDomainNftHoverState: () => disabledDomainNftHoverState()
	},

	// todo: is it safe to use the dotenv package, and get the env variables from here?
	//  Don't we send all env variables to the frontend then?
	showLocationsOnPortfolioPage: true,
	zoomLevelToShowNftsAndTheSelectorFrom: 15.5,
	showClickedLngLat: false,

	mobileBreakpoint: 640,

	// TODO Medium: Deprecate so we won't need to calculate this manually.
	// @ugly
	mapPixelTestYAdjustment: 39,

	northAmericaEnabled,
	multiSelectEnabled: () => multiSelectEnabled(),
	disabledResettingTheHoverStateWhenUnhovering: disabledResettingTheHoverStateWhenUnhovering(),
	enabledCustomDomainNftHexagonSelectorColor: enabledCustomDomainNftHexagonSelectorColor(),

	// Also change in app-engine/src/modules/profile/auth/termsAgreements.entity.ts
	termsVersion: Testing.isTesting ? 1 : 3,
	// https://github.com/duskload/mobile-device-detect: for some reason 'isMobile' is both mobile and tablet, and isMobileOnly is only mobile
	isMobileOrTablet: isMobile

}
