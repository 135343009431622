<!-- TODO Medium: Responsive -->
<template>

	<TransitionRoot
		data-testid="genericModal"
		as="template"
		:show="show">

		<Dialog
			as="div"
			class="fixed z-[110] inset-0 overflow-y-auto"
			:close="false"
			@close="closeModal">

			<div class="flex items-end justify-center min-h-full pt-4 px-4 pb-4 text-center sm:block sm:p-0">

				<TransitionChild
					as="template" enter="ease-out duration-300" enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-black/50 transition-opacity"/>
				</TransitionChild>

				<span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

				<TransitionChild
					as="template" enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

					<div
						class="text-base text-left transform transition w-full inline-block max-w-2xl md:px-4 md:my-8 align-middle lg:max-w-[832px]">

						<div class="w-full relative flex items-center bg-white overflow-hidden shadow-2xl shadow-xl rounded-2xl">

							<button
								v-if="$route.meta.showCloseButton !== false"
								type="button"
								data-testid="closeModal"
								class="absolute top-4 right-4 text-gray-400 z-30 focus:outline-none hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
								@click="closeModal">

								<svg class="h-6 w-6"
									 xmlns="http://www.w3.org/2000/svg"
									 fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										  d="M6 18L18 6M6 6l12 12"></path>
								</svg>

							</button>

							<div
								class="w-full h-full lg:flex overflow-x-hidden overflow-y-auto sm:max-h-screen"
								:class="{
									[isAndroid ? 'max-h-[calc(100vh-100px)]' : 'max-h-[calc(100vh-40px)]']: true,
									[$route.meta?.modal?.heightClass || 'md:h-[540px]']: true,
								}">

								<router-view
									name="dialog"
									v-slot="{ Component }">

									<transition
										name="fade"
										mode="out-in">

										<!-- @ugly Otherwise the element will be empty when going from /profile/credits/purchase/address-book/add to /profile/credits/purchase/address-book -->
										<div
											class="w-full h-full"
											:key="$route.path">

											<component
												:is="Component"/>

										</div>

									</transition>

								</router-view>

							</div>

						</div>
					</div>

				</TransitionChild>

			</div>

		</Dialog>

	</TransitionRoot>

</template>

<script lang="ts">

import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {defineComponent} from 'vue';
import _ from 'lodash';
import {closeRouteModal} from '@/components/layout/modalHelpers';

export default defineComponent({

	emits: ['close'],

	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
	},

	methods: {

		closeModal() {

			// Avoid closing?
			if (this.$route.meta?.showCloseButton === false)
				return;

			// Close modal.
			closeRouteModal(this);
		},

	},

	computed: {

		show() {
			return !!_.find(this.$route.matched, matchedRoute => !!matchedRoute?.components?.dialog);
		},

		isAndroid() {
			return /(android)/i.test(navigator.userAgent);
		},

	}

})

</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
	overflow: hidden;
}

.fade-enter,
.fade-leave-active {
	opacity: 0
}

</style>
