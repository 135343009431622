import Testing from "@/lib/Testing";

export default new class EngineApi {

	// TODO Critical: Respect live env.
	public buildUrl(path: string = '/'): string {

		// Error due to not starts with /?
		if (path.substring(0, 1) != '/')
			throw new Error('Path must start with /!');

		// Get port from setup.
		let engineUrl = new URL(import.meta.env.VITE_ENGINE_URL as string);

		// Get engine url.
		let engineUrlReconstructed = engineUrl.protocol + '//' + document.location.hostname + ':' + engineUrl.port;

		// Transform url.
		return Testing.transformPortForJestTesting(engineUrlReconstructed + path);
	}


}
