import type {Router} from "vue-router";

let lastMapControlInstance: any = null;

// @ugly Vue anti pattern.
export function setLastMapControlInstance(instance: any) {

	// Set
	lastMapControlInstance = instance;
}

export async function goToMapPlace(router: Router, result: any) {

	// Abort due to map was not loaded and failed navigating to it?
	if (router.currentRoute.value.path != '/map' && await router.push('/map'))
		return;

	// Delegate to Map Vue instance.
	(lastMapControlInstance as any).externGoTo(result);

	// Sent to GA?
	if (result?.text && (window as any).gtag) {

		(window as any).gtag!('event', 'page_view', {
			page_title: window.document.title,
			page_location: window.location.href,
			page_path: window.location.pathname,
			send_to: 'G-5E1042HXMQ'
		});

		(window as any).gtag('event', 'view_search_results', {
			search_term: result.text,
			send_to: 'G-5E1042HXMQ'
		});
	}
}

export async function goToMapWithWelcomeView(router: Router) {

	await router.push('/map');

	(lastMapControlInstance as any).externLoadWelcomeModal();
}


